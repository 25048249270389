import { AuthDef } from './index.module'
// import { REQUEST_METHOD } from '@src/http/http'
export default class {
    Login({
        POST_QUERY
    }, params) {
        // return FORM_POST(urls.login, params)
        const url = AuthDef.main.url;
        return POST_QUERY(url, params);
    }
}