import React from 'react';
import PageLoading from '@src/components/page_status';
import { 
    viewType,
    serviveMethod 
} from '@src/page_info_def';
/**
 * 模块namespace definition，
 */
export const path = '/home';

/** 
 * 权限描述： AuthDef => Authority Definition
*/
export const AuthDef = {
    main: {
        id: path,
        name: '疫情管理',
        parent: null,
        type: viewType.page,
        path: '/',
        serviceName: `${path}/getData`, // 不填则默认id
        // method: serviveMethod.GET,
        url: '/address/review',
        sort: 1
    },
    getPassList: {
        id: `${path}/getPassList`,
        name: '审核通过列表',
        parent: path,
        type: viewType.view,
        // path: path, // 不填则默认id
        serviceName: `${path}/getPassList`, // 不填则默认id
        method: serviveMethod.GET,
        url: '/address/passList',
    },
    pass: {
        id: `${path}/pass`,
        name: '审核通过',
        parent: path,
        type: viewType.view,
        // path: path, // 不填则默认id
        serviceName: `${path}/pass`, // 不填则默认id
        method: serviveMethod.POST,
        url: '/address/pass',
    },
    delete: {
        id: `${path}/delete`,
        name: '删除',
        parent: path,
        type: viewType.view,
        // path: path, // 不填则默认id
        serviceName: `${path}/delete`, // 不填则默认id
        method: serviveMethod.POST,
        url: '/address/delete',
    }
}

/**
 * 描述service请求方式
 *
 * @class Service
 */
export class Service {
}


const LoadPage = React.lazy(() => import(/* webpackChunkName:"home" */ './index.jsx'));
/**
 * 描述代码分割后页面组件
 *
 * @export function 表示页面入口组件
 * @returns React element
 */
export default function Page () {
    return (
        // Displays <Spinner> until OtherComponent loads
        <React.Suspense fallback={<PageLoading />}> 
            <LoadPage />
        </React.Suspense>
    );
}