import React from 'react';
import PageLoading from '@src/components/page_status';
import { viewType, serviveMethod } from '@src/page_info_def';
import { path as parentPath } from '../index.module';
/**
 * 描述模块namespace，应用至serviceName
 */
// export const modelName = `${parentPath}/view-registered`;
export const path = `${parentPath}/view-registered`
/** 
 * 权限描述： AuthDef => Authority Definition
*/
export const AuthDef = {
    main: {
        id: path,
        name: '页面功能注册',
        parent: parentPath,
        type: viewType.page,
        // path: path, // 不填则默认id
        serviceName: `${path}/getData`, // 不填则默认id
        method: serviveMethod.GET,
        url: '/admin/menu/allTree',
        sort: 1
    },
    delete: {
        id: `${path}/delete`,
        name: '删除',
        parent: path,
        type: viewType.view,
        serviceName: `${path}/delete`, // 不填则默认id
        method: serviveMethod.DELETE,
        url: '/admin/menu',
        sort: 5
    },
    add: {
        id: `${path}/add`,
        name: '增加',
        parent: path,
        type: viewType.view,
        serviceName: `${path}/add`, // 不填则默认id
        method: serviveMethod.POST,
        url: '/admin/menu',
        sort: 4
    },
    edit: {
        id: `${path}/edit`,
        name: '增加',
        parent: path,
        type: viewType.view,
        serviceName: `${path}/edit`, // 不填则默认id
        method: serviveMethod.PUT,
        url: '/admin/menu',
        sort: 3
    }
}

/**
 * 描述service请求方式
 *
 * @class Service
 */
export class Service {
}

const LoadPage = React.lazy(() => import(/* webpackChunkName:"view-registered" */ './index.jsx'));
/**
 * 描述代码分割后页面组件
 *
 * @export function 表示页面入口组件
 * @returns React element
 */
export default function Page () {
    return (
    // Displays <Spinner> until OtherComponent loads
    <React.Suspense fallback={<PageLoading />}> 
        <LoadPage />
    </React.Suspense>
  );
}