import global from './global';
import layoutState from './layout_state';
import dva from 'dva';
import createLoading from 'dva-loading';

export const app = dva({
    onError(err) {
        // console.error('dva onError', err)
        err.preventDefault();
    }
});
app.use(createLoading({
    loading: {
        global: false,
        models: {}
    }
})
)
export const initGlobalModels = () => {
    app.model(global);
    app.model(layoutState);
}
