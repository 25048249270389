import * as React from 'react';
import { Spin } from 'antd'

 
export interface IPageLoadingState {
    loading: boolean;
}
 
class PageLoading extends React.Component<any, IPageLoadingState> {
    constructor(props: any) {
        super(props);
        this.state = { loading: true };
    }
    public render() { 
        if (!this.state.loading) {
            return null
        }
        return (
            <Spin />
        );
    }
}
 
export default PageLoading;