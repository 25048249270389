import React from 'react'
import { Layout, Menu, Breadcrumb, Icon, Dropdown } from 'antd';
import { connect } from 'dva';
import { RENDER_METHOD } from '@src/models/const_define';
import _ from 'lodash';
import { NavLeftView } from './nav_view';
import { Flex, Box } from '@rebass/grid';
import logo from '../logo-top.png'
// import rApi from '@src/http';
import './index.less';

// const { SubMenu } = Menu;
const { Header, Content } = Layout;


export interface ITopLeftRightProps {
    activeNavs: any;
    siderWidth: number;
    collapsed: boolean;
    currentPath: {
        id: string
    };
    pageRenderMethod: string;
    selectedPage: string;
    pageHeight: number;
    dispatch: any;
    user: any;
    auth: any;
}
 
export interface ITopLeftRightState {
    topleftright: 'right'
}

/**
 * TOP LEFT RIGHT LAYOUT
 *
 * @class ITopLeftRight
 * @extends {React.Component<ITopLeftRightProps, ITopLeftRightState>}
 */
@connect(({ layoutState, global }: any) => {
    // console.log('loading', loading)
    return {
        ...layoutState,
        ...global
    }
})
class ITopLeftRight extends React.Component<ITopLeftRightProps, ITopLeftRightState> {

    public fullscreenView: any = null

    constructor(props: ITopLeftRightProps) {
        super(props);
        this.state = {
            topleftright: 'right'
        };
    }

    public renderBreadcrumb() {
        const { currentPath, selectedPage, auth } = this.props;
        // currentPath
        const breadcrumb: any = []
        // breadcrumb.push(currentPath)
        const getParent = (node: any) => {
            if (node) {
                breadcrumb.unshift(node)
                if (node.main && node.main.parent) {
                    getParent(auth[node.main.parent])
                }
            }
        }
        getParent(auth[((currentPath && currentPath.id) || selectedPage)])
        // console.log('breadcrumb', breadcrumb)
        // console.log('selectedPage', selectedPage, this.props)
        // const selected = navs.find(item => )
        // _.values()
        // activeNavs
        return (
            <Breadcrumb style={{ margin: '14px 0 10px 0' }}>
                {/* <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>List</Breadcrumb.Item>
                <Breadcrumb.Item>App</Breadcrumb.Item> */}
                {
                    breadcrumb.map((item: any) => {
                        return (
                            <Breadcrumb.Item key={item.main.id}>
                                {
                                    item.main.type === 'page' ?
                                    <a href={'#' + item.main.path}>{item.main.name}</a>
                                    :
                                    item.main.name
                                }
                            </Breadcrumb.Item>
                        )
                    })
                }
            </Breadcrumb>
        )
    }

    public handleClick = (e: any) => {
        const { dispatch } = this.props;
        // const dispatchAction = 
        dispatch({
            type: 'global/logout',
            payload: {}
        });
    }

    public render() {
        const { 
            children, 
            activeNavs,
            pageRenderMethod, 
            selectedPage, 
            pageHeight,
            user
        } = this.props;
        return (
            <Layout className='top-left-right'>
                <div style={{ height: '64px'}}>
                    <Header className="header" style={{ position: 'fixed', zIndex: 1, width: '100%', padding: 0, background: '#2A2926' }}>
                        <Flex alignItems='center'>
                            <Box>
                                <div style={{ marginLeft: 20 }} className="logo">
                                    <img src={logo} alt={'Logo'} />
                                </div>
                            </Box>
                            <Box flex={1} />
                            <Box>
                                <Dropdown trigger={['click']} overlay={
                                    <div style={{ background: 'white' }} className='drop-down-logout'>
                                        <Menu selectable={false} onClick={this.handleClick}>
                                            {/* <Menu.Item key='reload'>
                                        <Icon type="reload" />
                                        Reset
                                    </Menu.Item> */}
                                            <Menu.Item key='logout'>
                                                <Icon type="logout" />
                                                {'登出'}
                                            </Menu.Item>
                                        </Menu>
                                    </div>
                                }>
                                    <div style={{ 
                                        cursor: 'pointer',
                                        color: 'white', 
                                        // borderLeft: '1px solid #196a7d', 
                                        paddingLeft: 15, 
                                        marginLeft: 15, 
                                        minWidth: 100, 
                                        height: 30, 
                                        lineHeight: '30px', 
                                        textAlign: 'center' }
                                    }>
                                        {
                                            // <Avatar>
                                            //     USER
                                            // </Avatar>
                                        }
                                        {user.userName || 'USER'}&nbsp;
                                <Icon type="down" />
                                    </div>
                                </Dropdown>
                            </Box>
                            <Box width={'15px'} />
                        </Flex>
                        
                        {/* <Menu
                        theme="dark"
                        mode="horizontal"
                        defaultSelectedKeys={['2']}
                        style={{ lineHeight: '64px' }}
                    >
                        <Menu.Item key="1">nav 1</Menu.Item>
                        <Menu.Item key="2">nav 2</Menu.Item>
                        <Menu.Item key="3">nav 3</Menu.Item>
                    </Menu> */}
                        
                    </Header>
                </div>
                <Layout>
                    <NavLeftView 
                        title={false}
                        {...this.props} 
                    />
                    <Layout style={{ padding: '0 10px 10px', width: 'calc(100% - 260px)' }}>
                        
                        {this.renderBreadcrumb()}
                        <Content
                            style={{
                                background: '#fff',
                                margin: 0,
                                minHeight: 280
                            }}
                        >
                            <div 
                                className='fullscreenLevel' 
                                ref={v => this.fullscreenView = v} 
                                style={{ height: pageHeight }}
                            >
                                {
                                    ((pageRenderMethod === RENDER_METHOD.route) && children) ||
                                    _.values(activeNavs).map(item => {
                                        const moduleId = item.id;
                                        // const ModuleComponent: any = item.Component;
                                        // console.log('ModuleComponent', ModuleComponent)
                                        if (!item.Component) {
                                            // console.log('ModuleComponent', item, activeNavs)
                                            return null
                                        }
                                        return (
                                            <div key={moduleId} style={{ display: ((selectedPage === moduleId) && 'block') || 'none' }}>
                                                <item.Component {...this.props} pageHeight={pageHeight} />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Content>
                    </Layout>
                </Layout>
            </Layout>
        );
    }
}
 
export default ITopLeftRight;