/** 
 * @function 导入所有Services
 */
import _ from 'lodash';
const pageServicesFiles = require.context('../pages', true, /\.api.js$/);
const currentServicesFiles = require.context('../http', true, /\.api.js$/);

const moduleServices = require.context('../pages', true, /\.module.js$/);

const pageServicesKey = pageServicesFiles.keys();
const currentServicesKey = currentServicesFiles.keys();
const moduleServicesKey = moduleServices.keys();

// console.log('currentServicesKey', currentServicesKey)

let services: any = {};
const modules: any = {};
const auth: any = {};
const verifyModelName: any = {};
// console.log('moduleServices', moduleServices, moduleServicesKey);

function deletePathFileName(path: string) {
    const paths = path.split('/');
    paths.splice(paths.length - 1, 1);
    // console.log('deletePathFileName', paths, paths.join('/'))
    return (paths.length > 1) ? paths.join('/') : (paths[0] + '/');
}

function classToObject(params: any) {
    const reqFunc: any = {};
    if (params && params.prototype && params.prototype.constructor) {
        const api = params.prototype;
        // console.log('reqFunc name', Object.getOwnPropertyNames(api), api)
        for (const name of Object.getOwnPropertyNames(api)) {
            if (name !== 'constructor' && name !== 'undefined') {
                reqFunc[name] = api[name];
            }
        }
    }
    // console.log('reqFunc', reqFunc) b
    return reqFunc;
}
 
/**
 * 加载pages中的*.module.js中的
 *  页面组件(default) 
 *  权限声明(auth) 
 *  网络请求服务接口(Services)
 *
 */
function loadModules() {
    for (const key of moduleServicesKey) {
        try {
            const module = moduleServices(key);
            if (module) {
                const AuthDef = module.AuthDef;
                if (AuthDef) {
                    const moduleID = AuthDef.main.id;
                    const modelName = module.modelName;
                    const moduleComponent = module.default;
                    const Service = module.Service;
                    if (moduleComponent) {
                        if (modules[moduleID]) {
                            console.error(`module ID ${moduleID} have duplicate`);
                        }
                        modules[moduleID] = {
                            Component: moduleComponent,
                            ...AuthDef.main,
                            path: ('path' in AuthDef.main) ? AuthDef.main.path : AuthDef.main.id
                        };
                    }
                    auth[moduleID] = {
                        main: {
                            ...AuthDef.main,
                            path: ('path' in AuthDef.main) ? AuthDef.main.path : AuthDef.main.id
                        },
                        children: _.keys(AuthDef).filter(item => {
                            return item !== 'main'
                        }).map(item => {
                            return {
                                parent: AuthDef.main.id,
                                ...AuthDef[item]
                            }
                        }),
                    };
                    if (verifyModelName[modelName]) {
                        console.error(`module name ${ modelName } have duplicate`);
                    }
                    let servicesAutoCreate = {}
                    for (const anthkey in AuthDef) {
                        if (AuthDef[anthkey].serviceName) {
                            servicesAutoCreate = {
                                ...servicesAutoCreate,
                                [AuthDef[anthkey].serviceName || AuthDef[anthkey].id]: (method: any, params: any, config: any) => {
                                    return method[AuthDef[anthkey].method](AuthDef[anthkey].url, params, config)
                                }
                            }
                        }
                    }
                    if (Service) {
                        services[deletePathFileName(key)] = {
                            ...servicesAutoCreate,
                            ...classToObject(Service)
                        };
                    } else {
                        services[deletePathFileName(key)] = servicesAutoCreate
                    }
                }
              
            } else {
                console.warn(`module ${key} is null, please confirm this file`);
            }
            // console.log('path', key);
        } catch (e) {
            // tslint:disable-next-line:no-console
            console.error('批量导入所有文件夹module出错: ', e, key);
        }
    }
}

/**
 * 加载pages中的.api.js 里的 services
 * load *.api.js file services in dirname pages
 */
function loadPageServices() {
    for (const key of pageServicesKey) {
        try {
            const content = pageServicesFiles(key)
            // console.log('files content1', content)
            if (content && content.default) {
                // console.log('pageServicesFiles', key, content.default)
                if (typeof content.default === 'function') {
                    // console.log('content.default content1', content.default, content.default.name + i)
                    services[deletePathFileName(key)] = {
                        ...services[deletePathFileName(key)],
                        ...classToObject(content.default)
                    };
                    // console.log('content.default', content.default,  Object.keys(services[content.default.name]), Object.getOwnPropertyNames(services[content.default.name]))
                } else {
                    services = Object.assign({}, services, content.default);
                }
            }
        } catch (e) {
            // tslint:disable-next-line:no-console
            console.error('批量导入所有文件夹出错: ', e, key);
        }
    }
}

/** 
 * 加载http文件夹中的网络请求服务 *.api.js
 */
for (const key of currentServicesKey) {
    try {
        const content = currentServicesFiles(key)
        // console.log('files content2', content)
        if (content && content.default) {
            if (typeof content.default === 'function') {
                // console.log('currentServicesFiles', key, content.default)
                services[deletePathFileName(key) + 'public'] = classToObject(content.default);
                // console.log('content.default', content.default,  Object.keys(services[content.default.name]), Object.getOwnPropertyNames(services[content.default.name]))
            } else {
                services = Object.assign({}, services, content.default);
            }
        }
    } catch (e) {
        // tslint:disable-next-line:no-console
        console.error('批量导入所有文件夹出错: ', e, key);
    }
}
loadModules();
loadPageServices();
// console.log('load-services', modules, services, auth);
// console.log('services', services)
export {
    modules
}
export {
    services
}
export {
    auth
}