import React from 'react';
import { Router, Switch, Route, Redirect } from 'dva/router';
import { app } from '@src/models';
import Layout from '@src/layout';
import Login from '@src/pages/login';
import { ThemeProvider } from 'styled-components';
import { connect } from 'dva';
import _ from 'lodash';
import { modules } from '@src/page_info_def/load-services';
// import { Transition } from 'react-transition-group';
import TweenOne from 'rc-tween-one';
import { ConfigProvider, Spin } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import { getPermission } from '@src/components/permission'
const theme = {
    space: [0, 6, 12, 18, 24],
    breakpoints: ['32em', '48em', '64em']
};
// const LoginView = () => {
//     const [x, setX] = useState(0)
//     useEffect(() => {
//         return () => {
//             console.log('useEffect will unmount', x, setX)
//             setX(-1000)
//         }
//     }, [])
//     return (
//         <TweenOne
//             animation={{
//                 x: x,
//                 // scale: 0.5,
//                 // rotate: 120,
//                 // yoyo: true, // demo 演示需要
//                 // repeat: -1, // demo 演示需要
//                 duration: 500
//             }}
//             style={{ transform: 'translateX(0)' }}
//         >
//             <Login />
//         </TweenOne>
//     )
// }

@connect(({ global, loading }) => {
    // console.log('loading', loading)
    return {
        ...global,
        loading: loading.effects['global/getPermisson']
    };
})
class LoginOrLayout extends React.Component {

    state={
        ananimation: {
            x: 0,
            // scale: 0.5,
            // rotate: 120,
            // yoyo: true, // demo 演示需要
            // repeat: -1, // demo 演示需要
            ease: 'easeInSine',
            duration: 300,
            overflow: 'initial'
        },
        animationStyle: {}
    }

    constructor(props) {
        super(props)
        document.body.style.overflow = 'hidden';
        // setTimeout(() => {
        //     document.body.style.overflow = 'inherit';
        // }, 400)
        this.state.animationStyle = {
            transform: `translateX(${document.body.offsetWidth}px)`,
            overflow: 'hidden'
        }
        if (!props.isOpenEnterAnim) {
            this.state.ananimation = {}
            this.state.animationStyle = {}
        }
    }

    componentDidMount() {
        this.timer = setTimeout(() => {
            this.setState({
                ananimation: {},
                animationStyle: {}
            }) 
            document.body.style.overflow = 'inherit';
        }, 400)
        this.getPermisson()
    }

    getPermisson = () => {
        const {
            isLogin,
            dispatch
        } = this.props;
        if (isLogin) {
            dispatch({
                type: 'global/getPermisson',
                payload: {}
            });
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timer)
    }

    render() {
        const {
            isLogin,
            routes,
            loading,
            getPermissionError
        } = this.props;
        const {
            ananimation,
            animationStyle
        } = this.state
        // console.log('LoginOrLayout', loading)
        if (!isLogin) {
            return (
                <Redirect
                    to={{
                        pathname: "/login"
                        // search: "?utm=your+face"
                    }}
                />
            )
        }
        // console.log('routes', routes)
        // const defaultStyle = {
        //     transition: `transform ${300}ms ease-in-out`,
        //     transform: 'translate(0, -500px)',
        // }
        // const transitionStyles = {
        //     entering: { transform: 'translate(0, -500px)' },
        //     entered: { transform: 'translate(0, 0)' },
        //     exiting: { transform: 'translate(0, 0)' },
        //     exited: { transform: 'translate(0, -500px)' },
        // }
        // console.log('ananimation', ananimation, animationStyle)
        return (
            <TweenOne
                animation={ananimation}
                style={animationStyle}
            >
                {
                    (loading || (loading === undefined)) ?
                    <div><Spin /> 获取用户权限中</div>
                    :
                    getPermissionError ?
                    <div onClick={this.getPermisson}>获取权限失败，点击重新获取</div>
                    :
                    <Layout routes={routes} {...this.props} />
                }
                
            </TweenOne>
        )
    }

}

export default () => {
    app.router(({ history }) => {
        history.listen((location, action) => {
            // console.log('history', location, action)
            app._store.dispatch({
                type: 'layoutState/updateCurrentPath',
                payload: {
                    ...location
                }
            });
        });
        return (
            <ConfigProvider locale={zhCN}>
                <Router history={history}>
                    <ThemeProvider theme={theme}>
                            <Switch>
                                {/* {
                                _.values(modules).map(item => {
                                    return (
                                        <Route key={item.path} exact path={item.path} component={item.Component} />
                                    )
                                })
                            } */}
                                <Route
                                    exact
                                    path="/login"
                                    component={Login}
                                />
                                <Route
                                    path="/"
                                    render={(props) => (
                                        <LoginOrLayout {...props} routes={_.values(modules).filter(item => item.id !== 'Login' && getPermission(item.id))} />
                                    )}
                                />
                            </Switch>
                    </ThemeProvider>
                </Router>
            </ConfigProvider>
        )
    });
}


// class Router extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {  }
//     }
//     render() { 
//         return (
//             <div>

//             </div>
//         );
//     }
// }
 
// export default Router;