/** 
 *  View type definition, servives Method Transfer
 */
import { REQUEST_METHOD } from '@src/http/http';

export const serviveMethod = REQUEST_METHOD;

/**
 * view： 页面显示类容
 * page：页面
 * pageHide：非导航页面
 * category：导航类别
 */
export const viewType = {
    view: 'view',
    page: 'page',
    pageHide: 'page_hide',
    category: 'category'
};