import rApi from '@src/http';
import { IDvaModel } from './models';
import {
    // modules,
    auth
    // services
} from '@src/page_info_def/load-services';
import _ from 'lodash';
import { message } from 'antd'
import { formatLoaclTree } from '@src/pages/config/view_registered'

export interface ICertificate {
    userName?: string;
    id?: number | string | null;
    token?: string | null;
    tokenType?: string;
    refreshToken?: string;
    expiresIn?: string;
    jti?: string;
    license?: string;
}

export interface IPermission {
    permission?: any[];
    permissionToObject?: any;
}
export interface IModelsGlobalState extends IPermission {
    isLogin: boolean;
    isOpenEnterAnim: boolean;
    getPermissionError: boolean;
    errorMsg: string;
    user: ICertificate;
    userAuth: {};
    pageData: any;
}

interface IInitState {
    isLogin?: boolean;
    isOpenEnterAnim?: boolean;
    user?: ICertificate
}

const getCode = (item: any) => {
    if (item.main) {
        return item.main.code || item.main.id
    }
    return item.code || item.id
}

/**
 *  线上权限树形数组转换成id为key的object 
 *
 * @param {*} tree
 * @returns
 */
function onlineAuthToObject(tree: any[]) {
    const authObj: any = {}
    const recursive = (list: any[], parent=null, deep = 1) => {
        list.map(item => {
            const { children, ...view } = item;
            authObj[getCode(item)] = {
                ...(view.main || view),
                parent,
                deep
            };
            if (children && children.length > 0) {
                recursive(children, getCode(item), deep++);
            }
            return null
        })
    }
    recursive(tree);
    return authObj
}

function onlineAuthToLocalProperty(tree: any[], authObj: any) {
    const recursive = (list: any[]): any[] => {
        return list.map(item => {
            const { children } = item;
            if (!authObj[(item.code || item.id)]) {
                return null
            }
            return {
                ...(authObj[item.code || item.id].main || authObj[item.code || item.id]),
                children: children && children.length > 0 ? recursive(children).filter((ele: any) => ele) : []
            }
        })
    }
    // recursive(tree);
    return recursive(tree)
}

function initState(): IInitState {
    const user: (string | null) = localStorage.getItem('user');
    try {
        // tslint:disable-next-line:no-shadowed-variable
        const state: IInitState = {}
        if (user) {
            const userData: ICertificate = JSON.parse(user);
            if (userData.token) {
                state.isLogin = true
                state.isOpenEnterAnim = false
                state.user = userData
            }
        }
        return state;
    } catch (e) {
        return {};
    }
}

function stateTolLocalStorage(data: ICertificate) {
    //  console.log('stateTolLocalStorage', data)
    localStorage.setItem('user', JSON.stringify(data))
}

function responseFormat(data: any): ICertificate {
    return {
        token: data.access_token,
        tokenType: data.token_type,
        refreshToken: data.refresh_token,
        expiresIn: data.expires_in,
        jti: data.jti,
        license: data.license,
        id: data.userId,
        userName: data.userName
    }
}
const res = { "access_token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsaWNlbnNlIjoibWFkZSBieSBjb21tYSIsInVzZXJfbmFtZSI6ImFkbWluIiwic2NvcGUiOlsic2VydmVyIl0sImV4cCI6MTU4MDkxNDczMywidXNlcklkIjoxLCJhdXRob3JpdGllcyI6WyJST0xFX0FETUlOIiwiUk9MRV9VU0VSIl0sImp0aSI6IjA2YjIwNDA4LWQxMGQtNGQ0ZS05YTIxLTEyZGRiMDU1MDBhZCIsImNsaWVudF9pZCI6ImNvbW1hIn0.umVyuftGNbxunlCISScPnPLxtFSqLSb70GtyiUs3NSs", "token_type": "bearer", "refresh_token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsaWNlbnNlIjoibWFkZSBieSBjb21tYSIsInVzZXJfbmFtZSI6ImFkbWluIiwic2NvcGUiOlsic2VydmVyIl0sImF0aSI6IjA2YjIwNDA4LWQxMGQtNGQ0ZS05YTIxLTEyZGRiMDU1MDBhZCIsImV4cCI6MTU4MzQ2MzUzMywidXNlcklkIjoxLCJhdXRob3JpdGllcyI6WyJST0xFX0FETUlOIiwiUk9MRV9VU0VSIl0sImp0aSI6IjdhMGZjZGMxLWFhYzMtNGI0OC05NjdjLTQwMDM4N2ZkMDYwYiIsImNsaWVudF9pZCI6ImNvbW1hIn0.guoyYREcPGZDEUWU8D0-Kn1o5tC30wZ5R8KZa4AEmpE", "expires_in": 43110, "scope": "server", "license": "made by comma", "userId": 1, "jti": "06b20408-d10d-4d4e-9a21-12ddb05500ad" }

const model: IDvaModel<IModelsGlobalState> =  {
    namespace: 'global',
    state: {
        isLogin: false,
        isOpenEnterAnim: true,
        errorMsg: '',
        user: {
            userName: '',
            token: null,
            id: null
        },
        userAuth: {},
        pageData: {},
        permission: [],
        permissionToObject: {},
        getPermissionError: false,
        ...initState()
    },
    effects: {
        *login({ payload }, { call, put }) {
            try {
                // console.log('payload', payload)
                // let response = yield call(rApi.Login, payload);
                // console.log('response', response)
                let response: any = res
                response = responseFormat({
                    userName: payload.username,
                    ...response
                })
                yield put({
                    type: 'updateGlobalState',
                    payload: {
                        isLogin: true,
                        errorMsg: null,
                        user: {
                            ...response
                        }
                    }
                });
                // console.log('response', response)
                stateTolLocalStorage({
                    ...response
                })
                return response;
            } catch (error) {
                yield put({
                    type: 'updateGlobalState',
                    payload: {
                        errorMsg: error
                    }
                });
                message.error('用户名或密码错误，请确认')
                throw error;
                // return error
            }
        },
        // *getAuth({ payload }, { call, put }) {
        //     const auth: any = [];
        //     const userAuth = onlineAuthToObject(auth);
        //      yield put({
        //         type: 'updateGlobalState',
        //         payload: {
        //             userAuth
        //         }
        //      });
        // },
        *logout({ payload }, { call, put }) {
            stateTolLocalStorage({})
            yield put({
                type: 'updateGlobalState',
                payload: {
                    isLogin: false,
                    token: null,
                    permission: [],
                    user: {}
                }
            });
        },
        *getPermisson({ payload }, { call, put }) {
            yield put({
                type: 'updateGlobalState',
                payload: {
                    getPermissionError: false
                }
            });
            try {
                // const response = yield call(rApi.getUserPermisson, payload);
                const response = formatLoaclTree()
                // const response = yield call(function () { return new Promise((resolve, reject) => {})}, payload);
                let onlineAuth = response.filter((item: any) => {
                    return item.name !== '系统管理' && item.name !== '系统监控';
                });
                let userAuth: any = onlineAuthToObject(_.values(auth));
                const permissionToObject = _.keys(onlineAuthToObject(onlineAuth)).map((item: string) => {
                    if (!userAuth[item]) {
                        console.error('本地权限不存在请删除', item)
                    }
                    return userAuth[item]
                }).filter((item: any) => item);
                const permission = onlineAuthToLocalProperty(onlineAuth, auth)
                userAuth = []
                onlineAuth = []
                yield put({
                    type: 'updateGlobalState',
                    payload: {
                        permission,
                        permissionToObject
                    }
                });
            } catch (e) {
                yield put({
                    type: 'updateGlobalState',
                    payload: {
                        getPermissionError: true
                    }
                });
            }
        }
    },
    reducers: {
        /**
         * 设置 Page 间传递参数
         * @param state 
         * @param param1 
         */
        setPageData(state: IModelsGlobalState, { payload }: any) {
            if (!payload.id && payload.data) {
                return {
                    ...state
                }
            }
            return {
                ...state,
                pageData: {
                    ...state.pageData,
                    [payload.id]: payload.data
                }
            }
        }, 
        /**
         *  获取 Page 间传递参数
         *
         * @param {IModelsGlobalState} state
         * @param {*} { payload } id：当前页面数据，getPageData： function 接受Page 数据返回
         * @returns
         */
        getPageData(state: IModelsGlobalState, { payload }: any) {
            if (payload.getPageData && payload.id) {
                payload.getPageData(state.pageData[payload.id])
                return {
                    ...state,
                    pageData: {
                        ...state.pageData,
                        [payload.id]: null
                    }
                }
            }
            return {
                ...state
            }
        },
        updateGlobalState(state: IModelsGlobalState, { payload }: any) {
            return {
                ...state,
                ...payload
            }
        }
    }
}
export default model;