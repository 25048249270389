import axios, { AxiosTransformer } from 'axios';
import headersConfig from './http.header';
import NProgress from 'nprogress';
import { app } from '@src/models'
import 'nprogress/nprogress.css';

/** 
 * 增加顶部进度条
 */
function setAxiosRequestTopProgress() {
    if (axios.defaults.transformRequest && axios.defaults.transformResponse) {
        NProgress.configure({
            easing: 'linear',
            speed: 400
        });
        const transformRequest: AxiosTransformer[] = (axios.defaults.transformRequest as AxiosTransformer[]) || []
        const transformResponse: AxiosTransformer[] = (axios.defaults.transformResponse as AxiosTransformer[]) || []
        transformRequest.push((data: any, headers: any) => {
            NProgress.start();
            return data;
        });

        transformResponse.push((data: any, headers: any) => {
            NProgress.done();
            return data;
        });
    }
}


// const BASIC_AUTH = 'Basic YXBwOjEyMzQ1Ng=='
const BASIC_AUTH: string = 'Basic Y29tbWE6Y29tbWE=';

// console.log('axios', axios);
/**
 *  init http request at axios
 *
 * @export
 * @class InitAxios
 */
export default class InitAxios {
    public _store: any
    public _instance: any

    constructor(store: any) {
        this._instance = axios.create(Object.assign({}, {
            baseURL: process.env.NODE_ENV !== 'production' ? `/comma-dev` : 'https://road.smartcomma.com/',
            // baseURL: process.env.NODE_ENV !== 'production' ? `/comma-dev/commaiot/api` : '/api',
            // baseURL: process.env.NODE_ENV !== 'production' ? ` https://120.79.113.228/commaiot/api/` : 'https://120.79.113.228/commaiot/api/',
            // baseURL: process.env.NODE_ENV !== 'production' ? `api/app/mock/19/` : '/api/',
            // baseURL: process.env.NODE_ENV !== 'production' ? `/asm-rfid` : '/api',
            // baseURL: process.env.NODE_ENV !== 'production' ? `/comma-dev/api` : '/api',
            timeout: 10000, // ten seconds
            headers: {
                // 'Content-Type': 'application/json'
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            withCredentials: true
        }, headersConfig));
        this._initRequestHeaders();
        this._interceptors();
        setTimeout(() => {
            setAxiosRequestTopProgress();
        })
    }

    public logOut() {
        const dvaApp: any = app;
        const store = dvaApp._store;
        // store.dispatch('global/updateGlobalState', {
        //     isLogin: false
        // })
        store.dispatch({
            type: 'global/logout',
            payload: {
                isLogin: false
            }
        })
    }
    
    public _initRequestHeaders() {
        const _instance = this._instance;
        _instance.interceptors.request.use((config: any) => {
            if (headersConfig.type === 1) {
                return config;
            }
            // console.log('store', store)
            const dvaApp: any = app
            const store = dvaApp._store.getState().global;
            this._store = store;
            _instance.store = store;
            if (store.isLogin) {
                const accessToken = store.user.token;
                config.headers.Authorization = `Bearer ${accessToken}`;
            } else {
                config.headers.Authorization = BASIC_AUTH;
                // console.log('BASIC_AUTH', config.headers, store)
            }
            return config;
        }, (error: any) => {
            return Promise.reject(error);
        })
    }

    public _getInstance() {
        return this._instance;
    }

    public _interceptors() {
        // const store = this._store;
        const _instance = this._instance;
        _instance.interceptors.response.use((response: any) => {
            // console.log('err res response', response)
            if (response.status === 202 && response.data && response.data.errcode) {
                if (response.data.errcode === 403 || response.data.errcode === 401) {
                    this.logOut();
                }
            }
            return response;
        }, (error: any) => {
            // Do something with response error
            // console.error('error', error, error.config, error.response, error.status, error.code)
            // let code = error.status || error.response.data.errcode || error.response.data.status || error.response.status
            // console.log('interceptors response', error, JSON.parse(JSON.stringify(error)))
            const code: number = error.response ? error.status || error.response.data.errcode || error.response.data.status || error.response.status : error.status
            if (code === 401 || code === 403) {
                console.log('store', code)
                this.logOut();
            }
            if (code === 400) {
                // store.loginOut()
            }
            return Promise.reject(error);
        })
    }
}
