/* eslint-disable no-mixed-operators */

import React, { Component } from 'react';
// import _ from 'lodash';
import { Layout, Menu } from 'antd';
import { connect } from 'dva';
import logo from '@src/assets/image/logo.png';
import { RENDER_METHOD } from '@src/models/const_define';
import MenuRender from '../render_menu';
import ScrollArea from 'react-scrollbar';
// import _ from 'lodash';
// import './left_right.less';

// import { Link } from 'dva/router'
const { Sider } = Layout;

@connect(({ layoutState }) => {
    // console.log('loading', loading)
    return {
        ...layoutState,
    }
})
class NavView extends Component {

    static defaultProps = {
        title: true
    }

    state = {
        logoTitleWidth: 'auto'
    };

    componentDidMount() {
        if (this.logoTitle) {
            // dom render done, get h1 width as shrink animation
            this.setState({
                logoTitleWidth: this.logoTitle.offsetWidth
            })
        }
    }

    /**
     * get nvas click event
     *
     * @memberof NavView
     */
    onMenuItemClick = ({ item, key, keyPath }) => {
        // console.log('onMenuItemClick', item, item.props)
        const { history, dispatch, pageRenderMethod } = this.props;
        const itemData = item.props.data;
        // console.log('RENDER_METHOD.route', pageRenderMethod, RENDER_METHOD.route)
        switch (pageRenderMethod) {

            case RENDER_METHOD.tabs:
                dispatch({
                    type: 'layoutState/updatePageTabs',
                    payload: {
                        title: itemData.name,
                        id: itemData.code || itemData.id,
                        pageKey: itemData.code || itemData.id,
                        pageData: {}
                    }
                });
                break;

            case RENDER_METHOD.route:
                dispatch({
                    type: 'layoutState/updatePageTabs',
                    payload: {
                        title: itemData.name,
                        id: itemData.code || itemData.id,
                        pageKey: itemData.code || itemData.id,
                        pageData: {}
                    }
                });
                history.push(item.props.path);
                break;

            default:
                break;
        }
    }

    onCollapse = (collapsed ) => {
        const { dispatch } = this.props
        dispatch({
            type: 'layoutState/onCollapsed',
            payload: {
                collapsed 
            }
        })
    }

    render() {
        const { logoTitleWidth } = this.state;
        const { collapsed, permission, siderWidth, title } = this.props;
        // console.log('navs', navs)
        const navs = permission || []
        return (
            <div className='navs' style={{ width: siderWidth, height: 'calc(100vh - 64px)' }}>
                <ScrollArea
                    minScrollSize={100}
                    smoothScrolling
                    style={{
                        borderRight: '1px solid #ddd',
                        position: 'fixed',
                        top: '64px',
                        left: 0,
                        height: 'calc(100vh - 64px)',
                        background: 'white'
                    }}
                >
                <Sider
                    className='left-nav unselectable'
                    collapsible 
                    // trigger={null}
                    collapsed={collapsed}
                    onCollapse={this.onCollapse}
                    width={siderWidth}
                    theme={'light'}
                    style={{
                        // overflowY: 'auto',
                        // overflowX: 'hidden',
                        // height: 'calc(100vh - 64px)',
                       
                    }}
                >
                    {
                        title &&
                        <div className="logo">
                            <img src={logo} style={{ maxHeight: 30 }} alt="CommaTech Management" />
                            {
                                <h1
                                    ref={v => this.logoTitle = v} // dom render done, get h1 width as shrink animation
                                    className='logo-title'
                                    style={{ width: (!collapsed && logoTitleWidth || 0), marginLeft: !collapsed && 10 || 0 }}>
                                    COMMATECH MGT
                            </h1>
                            }
                        </div>
                    }
                   
                    <Menu
                        // theme="dark"
                        selectable={false}
                        mode={'inline'}
                        style={{ borderRight: 0 }}
                        onClick={this.onMenuItemClick}
                    >
                        {
                            MenuRender({
                                navs: [...navs.sort((curr, next) => curr.sort && next.sort ? curr.sort - next.sort : 0).filter(item => item.code !== 'HOME')]
                            })
                        }
                    </Menu>
                </Sider>
                </ScrollArea>
            </div>
        )
    }
}

export const NavLeftView = NavView;