import * as React from 'react';
// import softwareupdate from '@src/assets/svg/softwareupdate-24px.svg'
// import settings from '@src/assets/svg/settings-24px.svg'
// import mornitoring from '@src/assets/svg/mornitoring-24px.svg'
// import deviceManagement from '@src/assets/svg/device-management-24px.svg'
// import customer from '@src/assets/svg/customer-24px.svg'

/**
 * Customer Svg Icon: 客户
 * @param props
 */
// tslint:disable-next-line:variable-name
export const SvgCustomer = (props: any) => <svg width="1em" height="1em" viewBox="0 0 18 13" {...props}><g fill="none" fillRule="evenodd" opacity={0.5}><path d="M-3-6h24v24H-3z" /><path d="M13.305 5.778c1.383 0 2.495-1.133 2.495-2.528S14.688.722 13.305.722 10.8 1.855 10.8 3.25s1.122 2.528 2.505 2.528zm-7.2-.722c1.385 0 2.495-1.13 2.495-2.528C8.6 1.129 7.49 0 6.104 0A2.513 2.513 0 003.6 2.528a2.513 2.513 0 002.504 2.528zm6.945 2.888c-1.647 0-4.95.93-4.95 2.781V13H18v-2.275c0-1.85-3.303-2.78-4.95-2.78zM6.3 6.934c-2.097 0-6.3 1.182-6.3 3.538V13h6.3v-2.275c0-.86.297-2.366 2.133-3.509-.783-.182-1.539-.283-2.133-.283z" fill="#000" fillRule="nonzero" /></g></svg>;

/**
 * Device Management Svg Icon: 设备管理
 * @param props
 */
// tslint:disable-next-line:variable-name
export const SvgDeviceManagement = (props: any) => <svg width="1em" height="1em" viewBox="0 0 18 14" {...props}><g fill="none" fillRule="evenodd" opacity={0.5}><path d="M-3-5h24v24H-3z" /><path d="M0 14h18v-4H0v4zm2-3h2v2H2v-2zM0 0v4h18V0H0zm4 3H2V1h2v2zM0 9h18V5H0v4zm2-3h2v2H2V6z" fill="#000" fillRule="nonzero" /></g></svg>;

/**
 * Mornitoring Svg Icon: 设备管理
 * @param props
 */
// tslint:disable-next-line:variable-name
export const SvgMornitoring = (props: any) => <svg width="1em" height="1em" viewBox="0 0 18 10" {...props}><defs><path id="mornitoring-24px_svg__a" d="M0 0h24v24H0z" /></defs><g transform="translate(-3 -7)" fill="none" fillRule="evenodd" opacity={0.5}><mask id="mornitoring-24px_svg__b" fill="#fff"><use xlinkHref="#mornitoring-24px_svg__a" /></mask><path d="M21 8.667c0 .916-.736 1.666-1.636 1.666-.148 0-.287-.016-.418-.058l-2.912 2.958c.04.134.057.284.057.434 0 .916-.736 1.666-1.636 1.666-.9 0-1.637-.75-1.637-1.666 0-.15.017-.3.057-.434l-2.086-2.125a1.42 1.42 0 01-.425.059 1.42 1.42 0 01-.426-.059l-3.723 3.8c.041.134.058.275.058.425 0 .917-.737 1.667-1.637 1.667S3 16.25 3 15.333c0-.916.736-1.666 1.636-1.666.148 0 .287.016.418.058l3.73-3.792a1.498 1.498 0 01-.057-.433c0-.917.737-1.667 1.637-1.667S12 8.583 12 9.5c0 .15-.016.3-.057.433l2.086 2.125a1.42 1.42 0 01.426-.058c.147 0 .294.017.425.058l2.905-2.966a1.44 1.44 0 01-.058-.425c0-.917.737-1.667 1.637-1.667S21 7.75 21 8.667z" fill="#000" fillRule="nonzero" mask="url(#mornitoring-24px_svg__b)" /></g></svg>;

/**
 * Settings Svg Icon
 * @param props 
 */
// tslint:disable-next-line:variable-name
export const SvgSettings = (props: any) => <svg width="1em" height="1em" viewBox="0 0 16 16" {...props}><g fill="none" fillRule="evenodd" opacity={0.5}><path d="M-4-4h24v24H-4z" /><path d="M14.017 8.792l-.008.016c.032-.264.065-.536.065-.808s-.024-.528-.057-.792l.008.016L16 5.688l-1.967-3.376-2.322.928.008.008a6.221 6.221 0 00-1.384-.8h.008L9.97 0H6.029l-.356 2.456h.008c-.501.208-.963.48-1.384.8l.009-.008-2.331-.936L0 5.688l1.975 1.536.008-.016c-.033.264-.057.52-.057.792s.024.544.065.808l-.008-.016-1.7 1.32-.267.208 1.967 3.36 2.33-.92-.016-.032c.43.328.89.6 1.4.808h-.024L6.037 16h3.926s.024-.144.048-.336l.308-2.12h-.008c.501-.208.97-.48 1.4-.808l-.017.032 2.331.92 1.967-3.36s-.113-.096-.267-.208l-1.708-1.328zM7.996 10.8c-1.562 0-2.833-1.256-2.833-2.8 0-1.544 1.271-2.8 2.833-2.8 1.562 0 2.833 1.256 2.833 2.8 0 1.544-1.271 2.8-2.833 2.8z" fill="#000" fillRule="nonzero" /></g></svg>;
