/* eslint-disable no-mixed-operators */

import React, { Component } from 'react';
// import _ from 'lodash';
import { Layout, Menu, Icon } from 'antd';
import { connect } from 'dva';
import logo from '@src/assets/image/logo.png';
import { Flex, Box } from '@rebass/grid';
import version from '@src/version.ts';
import Tabs from '@src/components/tabs/index';
import { RENDER_METHOD } from '@src/models/const_define';
import MenuRender from '../render_menu';
import _ from 'lodash';
import './left_right.less';

// import { Link } from 'dva/router'
const { Header, Sider, Content } = Layout;

@connect(({ layoutState }) => {
    // console.log('loading', loading)
    return {
        ...layoutState,
    }
})
class NavView extends Component {

    static defaultProps = {
        title: true
    }

    state={
        logoTitleWidth: 'auto'
    };

    componentDidMount() {
        if (this.logoTitle) {
            // dom render done, get h1 width as shrink animation
            this.setState({
                logoTitleWidth: this.logoTitle.offsetWidth
            })
        }
    }

    /**
     * get nvas click event
     *
     * @memberof NavView
     */
    onMenuItemClick = ({ item, key, keyPath }) => {
        // console.log('onMenuItemClick', item, item.props)
        const { history, dispatch, pageRenderMethod } = this.props;
        const itemData = item.props.data;
        switch (pageRenderMethod) {

            case RENDER_METHOD.tabs:
                dispatch({
                    type: 'layoutState/updatePageTabs',
                    payload: {
                        title: itemData.name,
                        id: itemData.code || itemData.id,
                        pageKey: itemData.code || itemData.id,
                        pageData: {}
                    }
                });
            break;

            case RENDER_METHOD.route:
                history.push(item.props.path);
            break;
        
            default:
            break;
        }
    }

    render() {
        const { logoTitleWidth } = this.state;
        const { collapsed, navs, siderWidth, title } = this.props;
        // console.log('navs', this.props)
        return (
            <div style={{ width: siderWidth }}>
                <Sider
                    className='left-nav unselectable'
                    trigger={null}
                    collapsible
                    collapsed={collapsed}
                    width={siderWidth}
                >
                    {
                        title &&
                        <div className="logo">
                            <img src={logo} style={{ maxHeight: 30 }} alt="CommaTech Management" />
                            {
                                <h1
                                    ref={v => this.logoTitle = v} // dom render done, get h1 width as shrink animation
                                    className='logo-title'
                                    style={{ width: (!collapsed && logoTitleWidth || 0), marginLeft: !collapsed && 10 || 0 }}>
                                    COMMATECH MGT
                            </h1>
                            }
                        </div>
                    }
                    {
                        /* <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
                            <Menu.Item key="1">
                                <Icon type="user" />
                                <span>nav 1</span>
                            </Menu.Item>
                            <Menu.Item key="2">
                                <Icon type="video-camera" />
                                <span>nav 2</span>
                            </Menu.Item>
                            <Menu.Item key="3">
                                <Icon type="upload" />
                                <span>nav 3</span>
                            </Menu.Item>
                        </Menu> */
                    }
                    <Menu
                        theme="dark"
                        selectable={false}
                        mode={'inline'}
                        style={{ borderRight: 0 }}
                        onClick={this.onMenuItemClick}
                    >
                        {
                            MenuRender({
                                navs: [...navs.sort((curr, next) => curr.sort && next.sort ? curr.sort - next.sort : 0).filter(item => item.code !== 'HOME')]
                            })
                        }
                    </Menu>
                </Sider>
            </div>
        )
    }
}

export const NavLeftView = NavView

@connect(({ layoutState }) => {
    // console.log('loading', loading)
    return {
        ...layoutState,
    }
})
class LayoutView extends Component {

    state = {
        collapsed: false
    };

    // constructor(props) {
    //     super(props);
    // }

    componentDidMount() {
        document.addEventListener('fullscreenchange', this.onFullscreenChange)
    }

    toggle = () => {
        const {
            dispatch,
            collapsed
        } = this.props;

        dispatch({
            type: 'layoutState/onCollapsed',
            payload: {
                collapsed: !collapsed
            }
        })
    }

    updateNavsSort = (navs) => {
        const { dispatch } = this.props;
        dispatch({
            type: 'layoutState/updateNavsSort',
            payload: { navsSort: navs }
        })
    }

    onHeadItemClick = (itemData, event) => {
        // console.log('onHeadItemClick', itemData)
        const { history, dispatch, pageRenderMethod } = this.props;
        switch (pageRenderMethod) {
            case RENDER_METHOD.tabs:
                dispatch({
                    type: 'layoutState/updatePageTabs',
                    payload: {
                        title: itemData.name,
                        id: itemData.code || itemData.id,
                        pageKey: itemData.code || itemData.id,
                        pageData: {}
                    }
                });
                break;

            case RENDER_METHOD.route:
                history.push(itemData.path);
                break;

            default:
                break;
        }
    }

    onMenuItemClick = ({ item, key, keyPath }) => {
        // console.log('onMenuItemClick', item, item.props)
        const { history, dispatch, pageRenderMethod } = this.props;
        const itemData = item.props.data;
        switch (pageRenderMethod) {

            case RENDER_METHOD.tabs:
                dispatch({
                    type: 'layoutState/updatePageTabs',
                    payload: {
                        title: itemData.name,
                        id: itemData.code || itemData.id,
                        pageKey: itemData.code || itemData.id,
                        pageData: {}
                    }
                });
                break;

            case RENDER_METHOD.route:
                history.push(item.props.path);
                break;

            default:
                break;
        }
    }

    componentWillUnmount() {
        // document.addEventListener('fullscreenchange', this.onFullscreenChange)
        document.removeEventListener('fullscreenchange', this.onFullscreenChange)
    }

    onFullscreenChange = (e) => {
        // console.log('onFullscreenChange', e)
        const isFullScreen = document.mozFullScreen || document.webkitIsFullScreen || document.fullscreenElement
        if (!isFullScreen) {
            const { dispatch } = this.props;
            dispatch({
                type: 'layoutState/updateFullScreenState',
                payload: {
                    isFullScreening: false
                }
            });
        } else {
            const { dispatch } = this.props;
            dispatch({
                type: 'layoutState/updateFullScreenState',
                payload: {
                    isFullScreening: true
                }
            });
        }
    }

    onFullscreen = () => {
        const i = this.fullscreenView;
        // console.log('i', i)
        if (i.requestFullscreen) {
            i.requestFullscreen();
        } else if (i.webkitRequestFullscreen) {
            i.webkitRequestFullscreen();
        } else if (i.mozRequestFullScreen) {
            i.mozRequestFullScreen();
        } else if (i.msRequestFullscreen) {
            i.msRequestFullscreen();
        } else {
            return;
        }
        // const { dispatch } = this.props;
        // dispatch({
        //     type: 'layoutState/updateFullScreenState',
        //     payload: {
        //         isFullScreening: true
        //     }
        // });
    }

    onExitFullscreen = () => {
        // const i = this.fullscreenView;
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        } else {
            return;
        }
    }

    render() {
        const { children, siderWidth, collapsed, currentPath, activeNavs, pageRenderMethod, selectedPage, pageHeight } = this.props;
        // console.log('activeNavs', activeNavs, selectedPage)
        return (
            <Layout className='left-right'>
                <NavView {...this.props} />
                <Layout>
                    <Header 
                        className='header' 
                        style={{ 
                            background: '#fff', 
                            padding: 0,
                            width: `calc(100% - ${siderWidth}px)`
                        }}
                    >
                        <Flex>
                            <Box w={50}>
                                <div onClick={this.toggle} className="trigger">
                                    <Icon
                                        type={collapsed ? 'menu-unfold' : 'menu-fold'}
                                    /> 
                                </div>
                            </Box>
                            {
                                (pageRenderMethod === RENDER_METHOD.route) && (
                                    <Box w={100}>
                                        <h1 style={{ margin: 0 }}>
                                            {
                                                currentPath.name
                                            }
                                        </h1>
                                    </Box>
                                )
                            }
                            <Box flex={1}>
                                {/* <ScrollArea style={{ height: '100%', width: '200px' }} contentStyle={{ height: '100%' }}> */}
                                    <Tabs 
                                        onFullscreen={this.onFullscreen}
                                        onItemClick={this.onHeadItemClick} 
                                        selectedPage={selectedPage} 
                                        updateNavsSort={this.updateNavsSort} 
                                        navs={_.sortBy(_.values(activeNavs), 'navSort')} 
                                    />
                                {/* </ScrollArea> */}
                            </Box>
                            <Box w={50}>
                                <div style={{ height: '100%', padding: '0 5px' }}>
                                    <div className='avatar'>

                                    </div>
                                </div>
                            </Box>
                        </Flex>
                    </Header>
                    <Content
                        className='layout-content'
                        style={{
                            margin: '2px 8px',
                            marginTop: 64 + 6,
                            padding: 0,
                            background: '#fff', 
                            minHeight: 280
                        }}
                    >
                        <div className='fullscreenLevel' ref={v => this.fullscreenView = v} style={{ minHeight: pageHeight }}>
                            {
                                (pageRenderMethod === RENDER_METHOD.route) && children ||
                                _.values(activeNavs).map(item => {
                                    const moduleId = item.id;
                                    const ModuleComponent = item.Component;
                                    // console.log('ModuleComponent', ModuleComponent)
                                    if (!ModuleComponent) {
                                        // console.log('ModuleComponent', item, activeNavs)
                                        return null
                                    }
                                    return (
                                        <div key={moduleId} style={{ display: selectedPage === moduleId && 'block' || 'none' }}>
                                            <ModuleComponent  />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </Content>
                    <div style={{ height: 20, textAlign: 'center', color: '#aaa', fontSize: 12 }}>
                        <i>Copyright ©2017 Shenzhen CommaTach Technology All rights reserved. version {version.version}</i>
                    </div>
                </Layout>
            </Layout>
        );
    }
}

export default LayoutView;