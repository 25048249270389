import React, { Component } from 'react';
import { connect } from 'dva';
import TweenOne from 'rc-tween-one';
import { Form, Icon, Input, Button, Checkbox } from 'antd';
import { Flex, Box } from '@rebass/grid';
import { withRouter } from 'dva/router';
import PropTypes from 'prop-types';
import version from '@src/version.ts';
// import LinkedAnimate from './linked';
// import { md5hash } from '@src/utils/hash';
import './index.less';
import loginBackground from '../../assets/image/loginBg.png';
import { encryption } from '@src/utils'

/**
 * System login entry
 * @connect global and loading login api
 * @class Login
 * @extends {Component}
 */
@connect(({ global, loading }) => {
    // console.log('loading', loading)
    return {
        ...global,
        loading: loading.effects['global/login']
    }
}) 
class Login extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };
    
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            didMount: false,
            x: 0,
            transform: 'scale(0.98, 0.98)'
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                didMount: true
            })
        }, 10)
    }

    onMouseEnter = () => {
        this.setState({
            transform: 'scale(1, 1)'
        })
        // this.updateTweenData();
    }

    onMouseLeave = () => {
        // this.gather && this.updateTweenData();
        this.setState({
            transform: 'scale(0.98, 0.98)'
        })
        // this.interval = ticker.interval(this.updateTweenData, this.intervalTime);
    }

    submit = (e) => {
        e.preventDefault();
        const {
            history,
            dispatch
        } = this.props;
        // const {
        //     password,
        //     username
        // } = this.state;
        // const {
        //     dispatch
        // } = this.props;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const userInfo = encryption({
                    data: {
                        username: values.username,
                        password: values.password,
                        code: '',
                        randomStr: '' ,
                        scope: 'server',
                        grant_type: 'password'
                    },
                    key: '1234567887654321',
                    param: ['password']
                })
                const dispatchAction = dispatch({
                    type: 'global/login',
                    payload: {
                        // account: values.username,
                        // password: md5hash(values.password)
                        ...userInfo
                    }
                });
                dispatchAction.then(res => {
                    this.setState({
                        x: 0 - document.body.offsetWidth
                    })
                    // history.replace('/')
                    this.timer = setTimeout(() => {
                        history.replace('/home/index')
                    }, 50)
                })
            } else {
            }
        });
        // console.log('dispatchAction', dispatchAction)
    }

    componentWillUnmount() {
        clearTimeout(this.timer)
    }

    render() {
        const { loading, errorMsg } = this.props;
        const { 
            didMount, 
            transform,
            x
            // username,
            // password 
        } = this.state;
        const { getFieldDecorator } = this.props.form;
        const leftBg = {
            // position: 'absolute',
            top: 0,
            width: '100%',
            height: '100%',
            background: `url(${loginBackground}) no-repeat`,
            backgroundSize: 'cover'
        }
        const loginButtom = {
            width: '100%',
            background: '#FFBA30 100%',
            borderColor: '#FFBA30',
            borderRadius: '17px'
        }
        return (
            <TweenOne
                animation={{
                    x: x,
                    ease: 'easeOutSine',
                    // scale: 0.5,
                    // rotate: 120,
                    // yoyo: true, // demo 演示需要
                    // repeat: -1, // demo 演示需要
                    duration: 500
                }}
                style={{ transform: 'translateX(0)' }}
            >
            <div>
               
                {/* <div style={{ position: 'absolute', top: 0, right: '0',  width: '40%', height: '100%', background: '#292826' }}>
                </div> */}
                {/* <Flex>
                    <Box flex={1}>
                        <div style={leftBg}>
                        </div>
                    </Box>
                        <Box style={{ width: '30%', position: 'relative', maxWidth: 680, background: '#292826' }}>.
                        <Flex 
                            flexWrap='wrap'
                            justifyContent={'flex-end'} 
                            className="login-layout">
                            <Box style={{ width: '100%', }} alignSelf={'center'}>
                                <Form 
                                    style={didMount ? { transform: transform} : {}} 
                                    onSubmit={this.submit} 
                                    className="login-form">
                                    <h2 style={{ borderBottom: '5px solid #FFBA30', width: '52px', textAlign: 'center', margin: 'auto', marginBottom: '25px' }}>
                                        登录
                                    </h2>
                                    <Form.Item>
                                        {
                                            getFieldDecorator('username', {
                                                rules: [{ required: false}],
                                            })(
                                                <Input
                                                    style={{ background: 'none'}}
                                                    disabled={loading}
                                                    prefix={
                                                        <Icon type="user" style={{ color: '#aaa', border: 'none', fontSize: '20px' }} />
                                                    }
                                                    placeholder="Username"
                                                />
                                            )
                                        }
                                    </Form.Item>
                                    <Form.Item>
                                        {
                                            getFieldDecorator('password')(
                                                <Input
                                                    disabled={loading}
                                                    style={{ background: 'none'}}
                                                    prefix={<Icon type="lock" style={{ color: '#aaa', fontSize: '20px' }} />}
                                            type="password"
                                            placeholder="Password"
                                        />
                                    )
                                }
                                    </Form.Item>
                                    <Form.Item>
                                        <i style={{ color: 'red' }}>{errorMsg && errorMsg.message}</i>
                                        <div style={{ textAlign: 'left' }}>
                                            {
                                                getFieldDecorator('remember', {
                                                    valuePropName: 'checked',
                                                    initialValue: true,
                                                })(
                                                    <Checkbox>记住我</Checkbox>
                                                )
                                            }
                                        </div>
                                        <Button 
                                            loading={loading}
                                            style={loginButtom}
                                            type="primary" 
                                            htmlType="submit" 
                                            className="login-form-button">
                                            登录
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Box>
                        </Flex>
                        <div style={{ position: 'absolute', width: '100%', bottom: 0, fontSize: 10, textAlign: 'center', color: '#eee'}}>
                            <i>Copyright © 2017 Shenzhen Comma Technology All rights reserved v {version.version}</i>
                        </div>
                    </Box>
                </Flex> */}
                <Box>
                    <div style={leftBg}>
                        <Flex 
                            flexWrap='wrap'
                            justifyContent={'flex-end'} 
                            className="login-layout">
                            <Box style={{ width: '40%',margin: 'auto'}} alignSelf={'center'}>
                                <Form 
                                    style={didMount ? { transform: transform} : {}} 
                                    onSubmit={this.submit} 
                                    className="login-form">
                                    <h2 style={{ borderBottom: '5px solid #FFBA30', width: '52px', textAlign: 'center', margin: 'auto', marginBottom: '25px' }}>
                                        登录
                                    </h2>
                                    <Form.Item>
                                        {
                                            getFieldDecorator('username', {
                                                rules: [{ required: false}],
                                            })(
                                                <Input
                                                    border 
                                                    style={{ background: 'none'}}
                                                    disabled={loading}
                                                    prefix={
                                                        <Icon type="user" style={{ color: '#aaa', border: 'none', fontSize: '20px' }} />
                                                    }
                                                    placeholder="Username"
                                                />
                                            )
                                        }
                                    </Form.Item>
                                    <Form.Item>
                                        {
                                            getFieldDecorator('password')(
                                                <Input
                                                    disabled={loading}
                                                    style={{ background: 'none'}}
                                                    prefix={<Icon type="lock" style={{ color: '#aaa', fontSize: '20px' }} />}
                                            type="password"
                                            placeholder="Password"
                                        />
                                    )
                                }
                                    </Form.Item>
                                    <Form.Item>
                                        <i style={{ color: 'red' }}>{errorMsg && errorMsg.message}</i>
                                        <div style={{ textAlign: 'left' }}>
                                            {
                                                getFieldDecorator('remember', {
                                                    valuePropName: 'checked',
                                                    initialValue: true,
                                                })(
                                                    <Checkbox>记住我</Checkbox>
                                                )
                                            }
                                        </div>
                                        <Button 
                                            loading={loading}
                                            style={loginButtom}
                                            type="primary" 
                                            htmlType="submit" 
                                            className="login-form-button">
                                            登录
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Box>
                            <div style={{ position: 'absolute', width: '40%', bottom: 0, fontSize: 10, textAlign: 'center', color: '#eee'}}>
                                <i>Copyright © 2017 Shenzhen Comma Technology All rights reserved v {version.version}</i>
                            </div>
                        </Flex>
                    </div>
                </Box>
            </div>
            </TweenOne>
        );
        // return (
        //     <div>
        //         login
        //         <Button onClick={this.submit}>
        //             Submit
        //         </Button>
        //         <img src={logo} alt="" />
        //     </div>
        // );
    }
}
 
export default withRouter(Form.create({ name: 'login' })(Login));