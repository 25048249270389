import * as React from 'react';
import { app } from '@src/models';
import { IModelsGlobalState } from '@src/models/global';
const dvaApp: any = app;

interface IPermissionId {
    id: any;
}

export interface IPermissionProps extends IPermissionId {
    children: React.ReactChild | React.ReactChild[];
}
 
export interface IPermissionState {
    show: boolean;
}


export function getPermission(params: IPermissionId): boolean {
    const globalState: IModelsGlobalState = dvaApp._store.getState().global
    if (params.id === null) {
        return true
    } else if (globalState.permissionToObject && params.id) {
        return !!globalState.permissionToObject[params.id]
    }
    // return false;
    return true;
}

/**
 * 根据权限判断 view 是否显示
 *
 * @class IPermission
 * @extends {React.Component<IPermissionProps>}
 */
export function PermissionView(props: IPermissionProps) {
    const show = getPermission(props.id)
    if (!show) {
        return null;
    }
    return props.children;
}
 
export default PermissionView;