import React, { Component } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { Icon } from 'antd';
import { Menu, Item, Separator, MenuProvider } from 'react-contexify';
import arrayMove from 'array-move';
import ScrollArea from 'react-scrollbar';
import './index.less';
import 'react-contexify/dist/ReactContexify.min.css';
const AwesomeMenu = [
    {
        type: 'item',
        name: '关闭这个标签页',
        icon: 'close',
        value: 'close'
    },
    {
        type: 'item',
        name: '刷新这个标签页',
        icon: 'sync',
        value: 'refresh'
    },
    {
        type: 'item',
        name: '全屏这个标签页',
        icon: 'fullscreen',
        value: 'fullscreen'
    },
    {
        type: 'Separator'
    },
    {
        type: 'item',
        name: '关闭其他标签页',
        icon: 'arrows-alt',
        value: 'closeOther'
    },
    {
        type: 'item',
        name: '关闭所有标签页',
        icon: 'swap',
        value: 'closeAll'
    },
    {
        type: 'Separator'
    },
    {
        type: 'item',
        name: '关闭右侧标签页',
        icon: 'arrow-right',
        value: 'closeRight'
    },
    {
        type: 'item',
        name: '关闭左侧标签页',
        icon: 'arrow-left',
        value: 'closeLeft'
    }
]

const MyAwesomeMenu = ({ menuClick }) => {
    return (
        <Menu id='menu_id' style={{ zIndex: 1009 }}>
            {
                AwesomeMenu.map((item, index) => {
                    if (item.type === 'Separator') return <Separator key={index} />
                    return (
                        <Item data={{ value: item.value }} key={index} onClick={menuClick}>
                            <div className='flex' style={{ width: '100%' }}>
                                <div><Icon type={item.icon} /></div>
                                <div><span className='right-click-menu-text'>{item.name}</span></div>
                            </div>
                        </Item>
                    )
                })
            }
            {/* <Item data={{ value: 'refresh' }} onClick={menuClick}><Icon type="sync" /><span className='right-click-menu-text'>刷新</span></Item>
            <Separator />
            <Item data={{ value: 'closeOther' }} onClick={menuClick}><span className='right-click-menu-text'>关闭其他</span></Item>
            <Item data={{ value: 'closeAll' }} onClick={menuClick}><Icon type="swap" /><span className='right-click-menu-text'>关闭全部</span></Item>
            <Separator />
            <Item data={{ value: 'closeRight' }} onClick={menuClick}><Icon type="arrow-right" /><span className='right-click-menu-text'>关闭右侧所有</span></Item>
            <Item data={{ value: 'closeLeft' }} onClick={menuClick}><Icon type="arrow-left" /><span className='right-click-menu-text'>关闭左侧所有</span></Item> */}
        </Menu>
    )
}

// const getContentWidth = () => {
// }

const SortableItem = SortableElement(({ value, onItemClick, position, selectedPage }) => (
    <div 
        data-id={value.id}
        className={'hoc-item' + ((selectedPage === value.pageKey && ' hoc-item-active') || '')}
    >
            <MenuProvider data={{ position: position }} id="menu_id" >
                <div>
                    <div className='hoc-item-left-slash'></div>
                    <div
                        className='sortable_item_view'
                        data-id={value.id}
                    >
                        {value.name}
                        <Icon className='close' type="close" />
                    </div>
                    <div className='hoc-item-right-slash'></div>
                </div>
            </MenuProvider>
        </div>
    )
);

const SortableList = SortableContainer(({ items, onItemClick, selectedPage }) => {
    return (
        <div style={{ display: 'flex', paddingLeft: 10, height: '100%', alignItems: 'flex-end', userSelect: 'none', borderLeft: '1px solid #eee' }}>
        {
            items.map((value, index) => (
                <SortableItem 
                    selectedPage={selectedPage}
                    position={index}
                    onItemClick={onItemClick}
                    key={`item-${value.id}`}
                    index={index}
                    value={value} 
                />
            ))
        }
        </div>
    );
});

class Tabs extends Component {

    state = {
        items: [],
    };

    onSortEnd = ({ oldIndex, newIndex }) => {
        const { navs, updateNavsSort } = this.props;
        const idToSort = {};
        arrayMove(navs, oldIndex, newIndex).map((item, index) => {
            idToSort[item.id] = index + 1;
            return item
        });
        updateNavsSort(idToSort);
    }

    onItemClick = (value, event) => {
        const { onItemClick } = this.props;
        if (onItemClick) {
            onItemClick(value, event)
        }
    }

    shouldCancelStart = (e) => {
        let targetEle = e;
        if (!targetEle.className) {
            targetEle = e.target;
        }
        // console.log('targetEle', targetEle)
        if (targetEle.className === 'sortable_item_view') {
            // console.log('Div button click here ', targetEle);
            // perform you click opration here
            const clickId = targetEle.dataset.id;
            const { navs } = this.props;
            const item = navs.find(item => item.id === clickId);
            this.onItemClick(item, e);
        }
        // you can define multiple click event here using seprate uniq id of html element even you can manage delay on click here also using set timeout and sortmove props and sortend props you need to manage just one boolean flag.
    }

    rightClickWindowEvent = (event) => {
        // console.log('rightClickWindowEvent', event)
        const eventName = event.props && event.props.value
        if (eventName) {
            if (eventName === 'fullscreen') {
                const { onFullscreen } = this.props
                if (onFullscreen && typeof onFullscreen === 'function') {
                    onFullscreen()
                }
            }
        }
    }

    render() {
        const { navs, selectedPage } = this.props;
        // console.log('navs', navs)
        return (
            <React.Fragment>
                <MyAwesomeMenu menuClick={this.rightClickWindowEvent} />
                <ScrollArea style={{ height: '100%', width: '200px' }} contentStyle={{ height: '100%', width: '2000px' }}>
                    <SortableList
                        lockToContainerEdges
                        hideSortableGhost={true}
                        shouldCancelStart={this.shouldCancelStart}
                        axis={'x'}
                        lockAxis={'x'}
                        lockOffset={'0%'}
                        items={navs || []}
                        distance={2}
                        helperClass={'hoc-item-active'}
                        selectedPage={selectedPage}
                        onSortEnd={this.onSortEnd}
                        onItemClick={this.onItemClick}
                    />
                </ScrollArea>
            </React.Fragment>
        );
    }
}
 
export default Tabs;