import React, { Component } from 'react';
import { Route, Switch } from 'dva/router';
import LeftRightLayoutView from './left_right';
import TopBottomLayoutView from './top_bottom';
import TopLeftRightLayoutView from './top_left_right'
import { layoutConfig, LAYOUT_SHAPE } from './config'
// import { connect } from 'dva';
import './index.less';

const LayoutView = {
    [LAYOUT_SHAPE.LEFT_RIGHT]: LeftRightLayoutView,
    [LAYOUT_SHAPE.TOP_BOTTOM]: TopBottomLayoutView,
    [LAYOUT_SHAPE.TOP_LEFT_RIGHT]: TopLeftRightLayoutView
}
const LayoutRenderView = LayoutView[layoutConfig.layout]

const NoMatch = () => (
    <div>
        404
    </div>
);
/**
 * layout entry
 *
 * @class Layout
 * @extends {Component}
 */
// @connect(({ layoutState }) => {
//     return {
//         ...layoutState,
//     }
// })
class Layout extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() { 
        const { routes } = this.props;
        // console.log('LayoutRenderView', LayoutRenderView)
        // return null
        return (
            <LayoutRenderView {...this.props}>
                <Switch>
                    {/* {
                        routes.filter(item => item.id === '/home').map((route) => {
                            // console.log('route', route)
                            return <Route
                                exact
                                path={route.path}
                                key={route.path}
                                render={props => {
                                    // pass the sub-routes down to keep nesting
                                    return <route.Component key={route.path} {...props} />
                                }}
                            />
                        })
                    } */}
                    {
                        routes.map((route) => {
                            return <Route
                                exact
                                path={route.path}
                                key={route.path}
                                render={props => {
                                    // pass the sub-routes down to keep nesting
                                    return <route.Component key={route.path} {...props} />
                                }}
                            />
                        })
                    }
                    <Route component={NoMatch} />
                </Switch>
                {/* routes.map((route, i) => (
                <RouteWithSubRoutes key={i} {...route} />
                )) */}
            </LayoutRenderView>
        );
    }
}
 
export default Layout;