import React, { Component } from 'react';
import { 
    Layout, 
    // Icon, 
    Menu 
} from 'antd';
import { Flex, Box } from '@rebass/grid';
import { RENDER_METHOD } from '@src/models/const_define';
import logo from '@src/assets/image/logo.png';
import { connect } from 'dva';
import version from '@src/version.ts';
import _ from 'lodash';
import MenuRender from '../render_menu';
import './index.less';

const { Header, Content } = Layout;

@connect(({ layoutState }) => {
    // console.log('loading', loading)
    return {
        ...layoutState,
    }
})
class TopBottom extends Component {

    state = {
        logoTitleWidth: 'auto',
        current: 'HOME'
    };

    componentDidMount() {
        if (this.logoTitle) {
            // dom render done, get h1 width as shrink animation
            this.setState({
                logoTitleWidth: this.logoTitle.offsetWidth
            })
        }
    }

    onMenuItemClick = ({ item, key, keyPath }) => {
        const { history, dispatch, pageRenderMethod } = this.props;
        const itemData = item.props.data;
        switch (pageRenderMethod) {

            case RENDER_METHOD.tabs:
                dispatch({
                    type: 'layoutState/updatePageTabs',
                    payload: {
                        title: itemData.name,
                        id: itemData.code || itemData.id,
                        pageKey: itemData.code || itemData.id,
                        pageData: {}
                    }
                });
                break;

            case RENDER_METHOD.route:
                history.push(item.props.path);
                break;

            default:
                break;
        }
        this.setState({
            current: key
        });
    };

    render() {
        const { children, 
            // siderWidth, 
            collapsed, 
            // currentPath, 
            activeNavs, 
            navs, 
            pageRenderMethod,
            selectedPage 
        } = this.props;
        const { logoTitleWidth } = this.state;
        // console.log('navs', navs, selectedPage, [...navs.sort((curr, next) => curr.sort && next.sort ? curr.sort - next.sort : 0).filter(item => item.code !== 'HOME')])
        return (
            <Layout className='top-bottom'>
                {/* <NavView {...this.props} /> */}
                <Layout>
                    <Header
                        className='header'
                        style={{
                            padding: 0,
                            width: `100%`
                        }}
                    >
                        <Flex style={{width: '100%'}}>
                            <Box>
                                <div className="logo">
                                    <img src={logo} style={{ maxHeight: 30 }} alt="CommaTech Management" />
                                    {
                                        <h1
                                            ref={v => this.logoTitle = v} // dom render done, get h1 width as shrink animation
                                            className='logo-title'
                                            style={{ width: (!collapsed && logoTitleWidth) || 0, marginLeft: (!collapsed && 10) || 0 }}>
                                            COMMATECH MGT
                                        </h1>
                                    }
                                </div>
                            </Box>
                            <Box flex={1}>
                                <div style={{ width: '100%', height: '100%' }}>
                                    {/* <Tabs updateNavsSort={this.updateNavsSort} navs={_.sortBy(_.values(activeNavs), 'navSort')} /> */}
                                    <Menu
                                        // theme="dark"
                                        selectable={true}
                                        mode={'horizontal'}
                                        // style={{ borderRight: 0 }}
                                        selectedKeys={[this.state.current]}
                                        onClick={this.onMenuItemClick}
                                        style={{ width: '100%', height: 64, borderRight: 0, borderBottom: '1px solid #6d7697', color: '#aaa' }}
                                >
                                        {
                                            MenuRender({
                                                navs: [...navs.sort((curr, next) => curr.sort && next.sort ? curr.sort - next.sort : 0).filter(item => item.code !== 'HOME')],
                                                mode: 'horizontal'
                                            })
                                        }
                                    </Menu>
                                </div>
                            </Box>
                            <Box w={60}>
                                <div style={{ height: '100%', padding: '0 10px' }}>
                                    <div className='avatar'>

                                    </div>
                                </div>
                            </Box>
                        </Flex>
                    </Header>
                    <Content
                        className='layout-content'
                        style={{
                            marginTop: 64,
                            padding: 0,
                            background: '#fff',
                            minHeight: 280
                        }}
                    >
                        <div style={{ minHeight: document.body.offsetHeight - 78 - 6 }}>
                            {
                                ((pageRenderMethod === RENDER_METHOD.route) && children) ||
                                _.values(activeNavs).map(item => {
                                    const moduleId = item.id;
                                    const ModuleComponent = item.Component;
                                    return (
                                        <div key={moduleId} style={{ minHeight: document.body.offsetHeight - 78 - 6, display: ((selectedPage === moduleId) && 'block') || 'none' }}>
                                            <ModuleComponent minHeight={ document.body.offsetHeight - 78 - 6 } />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </Content>
                    <div style={{ height: 20, textAlign: 'center', color: '#aaa', fontSize: 12, background: '#12182e' }}>
                        <i>Copyright ©2019 Shenzhen CommaTach Technology All rights reserved. version {version.version}</i>
                    </div>
                </Layout>
            </Layout>
        );
    }
}
 
export default TopBottom;