// import React from 'react';
// import ReactDOM from 'react-dom';
import { app, initGlobalModels } from '@src/models';
import './public.less'
// import App from './App';
import 'moment/locale/zh-cn';
import routerInit from './router';
import * as serviceWorker from './serviceWorker';


initGlobalModels();
routerInit();
// app.router(() => < App />);
// const Main = 
app.start('#root');
// console.log('Main', Main, app)
// ReactDOM.render(<Main />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
serviceWorker.register();
