/** 
 *  @description 
 *  1.left-right 表示左边nav，右边title+content。
 *  2.top-bottom 表示顶部nave，下面content。
 *  3.top-left-right 表示顶部haeder，左边nav，右边title+content。
 */
export const LAYOUT_SHAPE = {
    LEFT_RIGHT: 1,
    TOP_BOTTOM: 2,
    TOP_LEFT_RIGHT: 3
}

export const layoutConfig = {
    // layout: LAYOUT_SHAPE.LEFT_RIGHT,
    layout: LAYOUT_SHAPE.TOP_LEFT_RIGHT,
    contentWidth: 'Fluid',
    fixedHeader: false,
    autoHideHeader: false,
    fixSiderbar: false,
    title: 'CommaTech Framework'
}
// {
//     "navTheme": "dark",
//     "primaryColor": "#1890FF",
//     "layout": "sidemenu",
//     "contentWidth": "Fluid",
//     "fixedHeader": false,
//     "autoHideHeader": false,
//     "fixSiderbar": false,
//     "menu": {
//         "disableLocal": false
//     },
//     "title": "Ant Design Pro",
//     "pwa": true,
//     "iconfontUrl": ""
// }