import _ from 'lodash';
// import rApi from '@src/http';
import { auth } from '@src/page_info_def/load-services';
import { modules } from '@src/page_info_def/load-services';
import { RENDER_METHOD } from './const_define';
import { layoutConfig, LAYOUT_SHAPE } from '@src/layout/config';
import { viewType } from '@src/page_info_def';
// console.log('modules', modules)
const collapsedWidth = 80;
const unCollapsedWidth = 260;
const pathToPage:any = {};
function listToTreeWithLevel(list: any[], parent: any, level: number) {
    const mergeNavs = []
    for (const node of list) {
        if (node.main.parent === parent) {
            node.level = level;
            const children = listToTreeWithLevel(list, node.main.id, level + 1)
            if (children.length) {
                node.children = children
            }
            mergeNavs.push({
                ...node.main,
                children: node.children
            })
            if (node.main.type === viewType.page || node.main.type === viewType.pageHide) {
                if (node.main.id === 'HOME') {
                    pathToPage['/'] = node.main
                } else {
                    pathToPage[node.main.path] = node.main
                }
            }
        }
    }
    return mergeNavs;
}

const navs = listToTreeWithLevel(_.values(auth), null, 0).filter(item => {
    return item.id !== 'Login' && item.id !== 'pageHide'
});

const getInitPageHeight = () => {
    // console.log('layoutConfig.layout', layoutConfig.layout)
    if (layoutConfig.layout === LAYOUT_SHAPE.TOP_LEFT_RIGHT) {
        return window.innerHeight - 78 - 20 - 30
    }
    return window.innerHeight - 78 - 20
}

export default {
    namespace: 'layoutState',
    state: {
        navs,
        auth,
        activeNavs: {
            HOME: {
                ...modules['HOME'],
                navSort: 1
            },
        },
        selectedPage: 'HOME',
        collapsed: false,
        siderWidth: unCollapsedWidth,
        currentPath: {},
        isFullScreening: false,
        pageRenderMethod: RENDER_METHOD.route,
        pageHeight: getInitPageHeight()
    },
    effects: {
        *onCollapsed({ payload }: any, { call, put, select }: any) {
            let siderWidth = select((state: { [x: string]: { siderWidth: any; }; }) => state['layoutState'].siderWidth);
            if (payload && 'collapsed' in payload) {
                if (payload.collapsed) {
                    siderWidth = collapsedWidth;
                } else {
                    siderWidth = unCollapsedWidth;
                }
            }
            yield put({
                type: 'updateLayoutState',
                payload: {
                    siderWidth,
                    ...payload
                }
            });
        }
    },
    reducers: {
        /**
         * tabs routers
         *
         * @param {*} state
         * @param {*} { payload }
         * @returns
         */
        changeActiveNavs(state: any, { payload }: any) {
            return {
                ...state,
                ...payload
            }
        },
        updatePageTabs(state: any, { payload }: any) {
            const activeNavs = state.activeNavs
            // let isHave = false
            // console.log('updatePageTabs', activeNavs['HOME'].navSort, payload.pageKey)
            if (activeNavs[payload.pageKey]) {
                activeNavs[payload.pageKey] = {
                    ...activeNavs[payload.pageKey],
                    ...payload,
                    navSort: payload.navSort || activeNavs[payload.pageKey].navSort
                }
            } else {
                activeNavs[payload.pageKey] = {
                    ...modules[payload.id],
                    ...payload,
                    navSort: _.values(activeNavs).length + 1
                }
            }
            // for (let i = 0; i < activeNavs.length; i++) {
            //     const nav = activeNavs[i]
            //     if (nav.pageKey === payload.pageKey) {
            //         isHave = true
            //         activeNavs[i] = {
            //             ...activeNavs[i],
            //             ...payload
            //         }
            //         break
            //     }
            // }
            // if (!isHave) {
            //     activeNavs.push({
            //         component: modules[payload.id],
            //         ...payload
            //     })
            // }
            // console.log('updatePageTabs', activeNavs)
            return {
                ...state,
                activeNavs,
                selectedPage: payload.pageKey
            }
        },
        updateCurrentPath(state: any, { payload }: any) {
            return {
                ...state,
                currentPath: {
                    ...pathToPage[payload.pathname]
                }
            }
        },
        updateLayoutState(state: any, { payload }: any) {
            return {
                ...state,
                ...payload
            }
        },
        updateNavsSort(state: any, { payload }: any) {
            const { navsSort } = payload
            const sortNavs: any = {}
            _.keys(state.activeNavs).map(key => {
                sortNavs[key] = {
                    ...state.activeNavs[key],
                    navSort: navsSort[key]
                }
                return key
            })
            // console.log('navsSort', sortNavs, payload)
            return {
                ...state,
                activeNavs: sortNavs
            }
        },
        updateFullScreenState(state: any, { payload }: any) {
            // console.log('updateFullScreenState', (document.body.offsetHeight - 78 - 20))
            return {
                ...state,
                isFullScreening: payload.isFullScreening,
                pageHeight: payload.isFullScreening ? window.innerHeight : (document.body.offsetHeight - 78 - 20)
            }
        }
    }
}