import React, { Component } from 'react';
import rApi from '@src/http';
import { AuthDef } from './index.module';
import { 
    // modules,
    auth
    // services
} from '@src/page_info_def/load-services';
import { Tree, Button } from 'antd';
import _ from 'lodash';
import { viewType } from '@src/page_info_def';
const TreeNode = Tree.TreeNode;
export const TYPES = {
    [viewType.category]: 1,
    [viewType.page]: 2,
    [viewType.pageHide]: 3,
    [viewType.view]: 4
};
// const localKeyToOnlineKey = {
//     // permission: undefined,
//     name: 'name',
//     // menuId: undefined,
//     // parentId: undefined,
//     url: 'url',
//     icon: 'icon',
//     sort: 'sort',
//     // component: undefined,
//     type: 'type',
//     method: 'method',
//     path: 'path'
// };
const allId = {};
// console.log('auth', auth, modules, services)

export const formatLoaclTree = () => {
    let tree = _.values(auth).filter(item => item.level === 0).sort((curr, next) => {
        let currSort = curr.sort || (curr.main && curr.main.sort)
        let nextSort = next.sort || (next.main && next.main.sort)
        return (currSort && nextSort) ? currSort - nextSort : 0
    })
    const clearMainKey = (item) => {
        if (item.main) {
            item = {
                ...item,
                ...item.main
            };
            delete item.main;
        }
        if (item.children) {
            // console.log('item.children', item.children)
            item.children = item.children.sort((curr, next) => {
                let currSort = curr.sort || (curr.main && curr.main.sort)
                let nextSort = next.sort || (next.main && next.main.sort)
                return (currSort && nextSort) ? currSort - nextSort : 0
            });
            item.children = item.children.map(ele => clearMainKey(ele));
        } else {
            item.chidren = [];
        }
        allId[item.id] = item.id;
        return item;
    }
    return tree.map(item => {
        return clearMainKey(item);
    })
}

// console.log('auth', _.values(auth).filter(item => item.level === 0))
class ViewRegistered extends Component {

    idToValue={};
    codeToValue={};
    codeToId={};

    constructor(props) {
        super(props);
        this.state = {
            localTree: formatLoaclTree(),
            onlineTree:[]
        }
    }

    componentDidMount() {
        this.loadData()
        // console.log('modules', modules, services)
    }

    getValue = (value, code) => {
        if (value[code]) {
            return value[code];
        } else if (value['main'] && value['main'][code]) {
            return value['main'][code];
        }
        return null;
    }

    treeNode = (preitem, i) => {
        i++
        return (
            <TreeNode
                data={preitem}
                title={this.getValue(preitem, 'name')}
                key={this.getValue(preitem, 'code') || this.getValue(preitem, 'id')}
                type={this.getValue(preitem, 'type')}
                // childrenData={preitem.children}
            >
                {
                    (preitem.children && preitem.children.length > 0) || (preitem.subFunctions && preitem.subFunctions.length > 0) ?
                        (preitem.children || preitem.subFunctions).map(item => {
                            return this.treeNode(item, i)
                        })
                        :
                        null
                }
            </TreeNode>
        )
    }

    addNew = () => {
        const { localTree } = this.state
        let flat = []
        const recursive = (item = {}, parent = null) => {
            const { children, ...ele } = item
            flat.push({
                ...ele,
                parent: parent
            })
            if (children && children.length > 0) {
                children.forEach(element => {
                    recursive(element, ele.id)
                })
            }
        }
        localTree.map(ele => {
            recursive(ele)
            return ele
        })
        // console.log('flat', flat)
        this.flat = flat
        this.request(flat)
    }

    // sortOnline = () => {
    //     const { permissions } = this.state
    //     let flat = []
    //     const recursive = (item = {}, index = 0, parent = null) => {
    //         const { subFunctions, ...ele } = item
    //         flat.push({
    //             ...ele,
    //             sort: index + 1,
    //             parent: parent
    //         })
    //         if (subFunctions && subFunctions.length > 0) {
    //             subFunctions.forEach((element, i) => {
    //                 recursive(element, i, ele.id)
    //             })
    //         }
    //     }
    //     permissions.map((ele, index) => {
    //         recursive(ele, index)
    //     })
    //     // console.log('flat', flat)
    //     this.flat = flat
    // }

    request = async (flat, noDelete = false) => {
        // const keys = Object.keys(this.codeToValue).map(item => {
        //     return {
        //         code: this.codeToValue[item].code,
        //         id: this.codeToValue[item].id
        //     }
        // })
        // if (!noDelete) {
        //     for (let i = 0; i < keys.length; i++) {
        //         let item = keys[i]
        //         if (!allId[item.code]) {
        //             let res = await rApi[AuthDef.delete.serviceName]({
        //                 id: item.id
        //             })
        //             console.log('remove', item.code, item.id, res)
        //         }
        //     }
        // }
        // let count = 0
        // console.log('request', flat, this.codeToId)
        // return
        this.setState({ loadingLoc: true })
        for (let i = 0; i < flat.length; i++) {
            let item = flat[i]
            if (!item.parent && !this.codeToId[item.id]) {
                console.log('ADD NEW 2', item.id)
                let data = {
                    parentId: -1,
                    permission: item.id,
                    // menu_id: item.id,
                    name: item.name,
                    nameKey: item.nameKey,
                    url: item.url,
                    type: TYPES[item.type],
                    sort: item.sort,
                    method: item.method ? item.method.split('_')[0] : null,
                    terminalType: item.terminalType
                }
                let res = await rApi[AuthDef.add.serviceName](data)
                console.log('ADD NEW 2', item.name, this.codeToId[item.parent] )
                this.codeToId[data.permission] = res
                // flat = flat.filter(ele => ele.id !== data.id)
            } else if (this.codeToId[item.id]) {
                let data = {
                    parentId: (typeof item.parent === 'number' && item.parent) || this.codeToId[item.parent] || -1,
                    id: (typeof item.id === 'number' && item.id) || this.codeToId[item.id],
                    menuId: (typeof item.id === 'number' && item.id) || this.codeToId[item.id],
                    permission: item.id,
                    // menu_id: item.id,
                    name: item.name,
                    nameKey: item.nameKey,
                    url: item.url,
                    type: TYPES[item.type],
                    sort: item.sort,
                    method: item.method ? item.method.split('_')[0] : null,
                    terminalType: item.terminalType
                }
                let haveUpdate = true

                const checkKeys = ['name', 'sort', 'type', 'url']
                checkKeys.map(ele => {
                    if (this.codeToValue[item.id] && (data[ele] !== this.codeToValue[item.id][ele])) {
                        console.log('this.codeToValue', item.id, data[ele], '-', ele, '-', this.codeToValue[item.id][ele])
                    }
                    haveUpdate = haveUpdate && this.codeToValue[item.id] && (data[ele] !== this.codeToValue[item.id][ele])
                    // haveUpdate = haveUpdate || true
                    return ele
                })
                if (haveUpdate) {
                    let res = await rApi[AuthDef.edit.serviceName](data)
                    console.log('UPDATE', item.name, res, item.id, item.parent, this.codeToId[item.parent])
                    // this.codeToId[data.permission] = res
                }
                // flat = flat.filter(ele => ele.id !== item.id)
            } else if (item.parent && this.codeToId[item.parent]) {
                let data = {
                    parentId: this.codeToId[item.parent],
                    permission: item.id,
                    // menu_id: item.id,
                    name: item.name,
                    nameKey: item.nameKey,
                    url: item.url,
                    type: TYPES[item.type],
                    sort: item.sort,
                    method: item.method ? item.method.split('_')[0] : null,
                    terminalType: item.terminalType
                }
                let res = await rApi[AuthDef.add.serviceName](data)
                console.log('ADD NEW 1', item.name, res, item.id, item.parent, this.codeToId[item.parent])
                this.codeToId[item.id] = res
                // flat = flat.filter(ele => ele.id !== item.id)
            }
            // flat = flat.filter(ele => ele.id !== item.id)
            // console.log('flat', flat, flat.length, item)
        }
        // console.log('flat', this.codeToId)
        this.setState({ loadingLoc: false })
        this.loadData()
    }

    loadData = () => {
        this.setState({ loadingLine: true })
        rApi[AuthDef.main.serviceName]().then(res => {
            const recursive = (item = {}, parent = null) => {
                const { children, ...ele } = item
                if (ele.code)
                    this.codeToValue[ele.code] = ele
                    this.codeToId[ele.code] = ele.id
                    // this.codeToItem[ele.code] = ele
                if (children && children.length > 0) {
                    children.forEach(element => {
                        recursive(element, ele.id)
                    })
                }
            }
            res.map(ele => {
                recursive(ele)
                return ele
            })
            // console.log('this.codeToValue', this.codeToId, this.codeToId['/client_center/client_mgt'])
            this.setState({
                onlineTree: res.sort((curr, next) => {
                    let currSort = (curr && curr.sort) || (curr && curr.main && curr.main.sort) || 1
                    let nextSort = (next && next.sort) || (next && next.main && next.main.sort) || 1
                    return (currSort && nextSort) ? currSort - nextSort : 0
                }),
                loadingLine: false
            })
            // console.log('this.codeToId', this.codeToId)
        }).catch(e => {
            console.error('loadData', e)
            this.setState({ loadingLine: false })
        })
    }

    render() { 
        const {
            loadingLoc,
            loadingLine,
            localTree,
            onlineTree
        } = this.state;
        // console.log('onlineTree', localTree, onlineTree)
        return (
            <div className='flex' style={{ padding: 20 }}>
                <div style={{ width: 600 }}>
                    <h2>
                        Loction View Tree Structure
                        </h2>
                    <p>
                        <Button loading={loadingLoc || loadingLine} onClick={this.addNew}>Register</Button>
                    </p>
                    <Tree
                        draggable
                        onDragEnter={this.onDragEnter}
                        onDrop={this.onDrop}
                        onExpand={this.onExpand}
                        onSelect={this.onSelect}
                    >
                        {
                            localTree ? localTree.map(preitem => {
                                return this.treeNode(preitem, 0)
                            })
                            : 
                            null
                        }
                    </Tree>
                </div>
                <div style={{ width: 600 }}>
                    <h2>
                        Online View Tree Structure(Darg Sort)
                        </h2>
                    <p>
                        <Button loading={loadingLine} onClick={this.registerPowerOnline}>Change Sort</Button>
                        &emsp;
                        <Button loading={loadingLine} onClick={this.onDelete}>Delete</Button>
                        &emsp;
                        <Button loading={loadingLine} onClick={this.loadData}>reload</Button>
                    </p>
                    <Tree
                        draggable
                        onDragEnter={this.onDragEnter}
                        onDrop={this.onDrop1}
                        onExpand={this.onExpand}
                        onSelect={this.onSelect}
                    >
                        {
                            //this.renderTreeNodes(treeData)
                            onlineTree ? onlineTree.map(preitem => {
                                return this.treeNode(preitem, 0)
                            }) : null
                        }
                    </Tree>
                </div>
            </div>
        );
    }
}

export default ViewRegistered;