// import { md5hash } from '../utils/hash'
export default class {
    /**
     * 获取用户权限
     *
     * @param {*} {GET}
     * @returns
     */
    getUserPermisson({ GET }) {
        return GET('/admin/menu/userMenu')
    }

}